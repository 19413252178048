import {
  FormControl,
  Input,
  InputAdornment,
  Button,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import "./usw.css";

const expDate = "2023-12-31";

function Usw() {
  const [input, setInput] = useState("");
  const [totalSaved, setTotalSaved] = useState("0.00");

  const handleInput = (text) => {
    if (!isNaN(text)) {
      setInput(text.trim());
    }
  };

  const handleClick = () => {
    let weeks = moment(expDate).diff(moment(), "weeks");
    setTotalSaved((weeks * input).toFixed(2));
  };

  return (
    <>
      <div className="top">
        <div className="header">
          <div className="usw-header-title">Strike Savings Calculator</div>
        </div>
        <div
          style={{
            margin: 20,
            paddingRight: 10,
            paddingLeft: 10,
            textAlign: "left",
            fontSize: 14,
            lineHeight: 1.2,
          }}
        >
          Among the most important things we do is negotiate together for contracts that protect us at work and pay us the wages and benefits we earn with dignity.
          <br /><br />
          We never know how bargaining with our employers is going to go, so find out here how much you need to save in case we ever are forced to strike.
        </div>
      </div>
      <div className="bottom usw-bg">
        <div style={{ margin: 30 }}>
          <div className="calc">
            <div>
              Enter amount you can <br /> set aside each week
            </div>
            <FormControl
              sx={{ m: 1, marginTop: 5, width: 347 }}
              variant="standard"
            >
              <Input
                id="standard-adornment-amount"
                value={input}
                placeholder="00.00"
                onChange={(e) => handleInput(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
            <Button onClick={handleClick} color="warning" variant="contained">
              Calculate Savings
            </Button>
          </div>
        </div>
        <div style={{ color: "white" }} className="total">
          You would save a total of
          <p style={{ fontSize: 32, fontFamily: "HD Colton semibold" }}>
            ${totalSaved}
          </p>
          by the date of contract expiration <br /> (December 31, 2023)
        </div>
      </div>
    </>
  );
}

export default Usw;
