import { BrowserRouter, Routes, Route } from "react-router-dom";
import Ups from "./Ups";
import Usw from "./Usw";

function App() {


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Ups />} />
          <Route path="/usw" element={<Usw />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
