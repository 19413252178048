import {
  FormControl,
  Input,
  InputAdornment,
  Button,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import "./App.css";
import logo from "./strikeCalcLogo.png";

const expDate = "2023-07-31";

function Ups() {
  const [input, setInput] = useState("");
  const [totalSaved, setTotalSaved] = useState("0.00");

  const handleInput = (text) => {
    if (!isNaN(text)) {
      setInput(text.trim());
    }
  };

  const handleClick = () => {
    let weeks = moment(expDate).diff(moment(), "weeks");
    setTotalSaved((weeks * input).toFixed(2));
  };

  return (
    <div className="App">
      <div className="top">
        <div className="header">
          <img className="logo" alt="Strike Savings Logo" src={logo} width={80} />
          <div className="header-title">Strike Savings Calculator</div>
        </div>
        <div
          style={{
            margin: 20,
            paddingRight: 10,
            paddingLeft: 10,
            textAlign: "left",
            fontSize: 14,
            lineHeight: 1.2,
          }}
        >
          As we get ready to face management in negotiations, we need to be
          ready to take on UPS at every level, up to and including going on
          strike. While the Teamsters Strike Fund is strong, members still need
          to be financially prepared in the event of a strike.This preparation
          will put us in a stronger position and show UPS that we are ready to
          take action if necessary.
          <br />
          <br /> Putting a few extra dollars aside each week will add up to a
          lot of valuable savings.
          <br />
          <br /> Please encourage your coworkers to save as well and use this
          simple calculator tool below to determine how much you can save weekly
          before the current contract expires.
        </div>
      </div>
      <div className="bottom">
        <div style={{ margin: 30 }}>
          <div className="calc">
            <div>
              Enter amount you can <br /> set aside each week
            </div>
            <FormControl
              sx={{ m: 1, marginTop: 5, width: 347 }}
              variant="standard"
            >
              <Input
                id="standard-adornment-amount"
                value={input}
                placeholder="00.00"
                onChange={(e) => handleInput(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
            <Button onClick={handleClick} color="warning" variant="contained">
              Calculate Savings
            </Button>
          </div>
        </div>
        <div style={{ color: "white" }} className="total">
          You would save a total of
          <p style={{ fontSize: 32, fontFamily: "HD Colton semibold" }}>
            ${totalSaved}
          </p>
          by the date of contract expiration <br /> (July 31, 2023)
        </div>
      </div>
    </div>
  );
}

export default Ups;
